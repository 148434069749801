body {
  height: 100%;
  background-color: $white;
  overflow-x: hidden;
  overflow-y: auto !important;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: normal;
  font-family: $font-family, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  font-variant-ligatures: common-ligatures;
  color: $black;
}

body * {
  margin: 0;
  transition: 0.2s ease-in-out;
  font-weight: 500;
}

.dragg {
  transition: none;
}

a:hover {
  color: $primary;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

li {
  list-style-type: none;
}

small {
  font-size: 12px;
}

img {
  /* Makes sure image is never bigger than its parent */
  max-width: 100%;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $g4;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $g4;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $g4;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $g4;
}

button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

button {
  outline: none;
}

.background_icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.backDrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: $black;
  left: 0;
  opacity: 0.2;
  z-index: 10;
}

button.ant-btn.ant-btn-primary {
  background-color: $primary;
  transition: none;
  border: none;
  transition: 0.25s ease-in-out;
  font-weight: 600;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}

button.ant-btn.ant-btn-lg {
  padding: 10px 40px;
  height: 53px;
}

label.ant-checkbox-wrapper {
  padding: 15px;
  background: $g1;
  border-radius: 4px;
  margin-bottom: 8px;

  &:hover {
    background: $g2;
  }

  .ant-checkbox + span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding-right: 12px;
    padding-left: 12px;
  }

  .ant-checkbox-inner::after {
    display: block;
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $primary;
    border-color: $light-orange;
  }

  .ant-checkbox-checked::after {
    border-color: $red;
  }
}

.breadcrumb-icon.breadcrumb-icon {
  span {
    color: $black;
    margin-right: 10px;
  }
}

.ant-switch-checked {
  background-color: $primary;
}

.ant-modal-root {
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }

  .ant-modal-mask {
    background: #00000080;
    backdrop-filter: blur(8px);
  }

  div.ant-modal-title {
    font-size: 24px;
    line-height: 135%;
  }

  .ant-modal-header {
    border-bottom: 1px solid $g3;
    text-align: center;
  }
}

.ant-radio-group {
  display: flex;
  flex-flow: column;

  label.ant-radio-wrapper {
    padding: 12px;
    background-color: $g1;
    border-radius: 4px;
    margin-bottom: 8px;

    &:hover {
      background-color: $g2;

      .ant-radio {
        border-color: $primary;
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px rgba(237, 123, 48, 0.2);
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $primary;

      &::after {
        background-color: $primary;
      }
    }
  }
}

th.ant-table-cell {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $g5 !important;
}

div.paginatedButtons {
  display: flex;
  justify-content: flex-end;

  button {
    transform: none !important;
  }

  > button:nth-child(1) {
    margin-right: 10px;
  }
}
