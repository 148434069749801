$black: #012e40;
$dark-black: #012938;
$tab-grey: #263238;
$white: #ffffff;
$w2: #f8f8f8;
$w1: #fcfcfc;
$primary: #f55148;
$red50: #fee6e5;
$green: #00ba88;
$mid-green: #1b5e20;
$dark-green: #004d40;
$pink: #ed2e7e;
$orange: #fe7f2d;
$light-orange: #fff3e0;
$red: #f44336;
$dark-red: #bf360c;
$blue: #2962ff;
$dark-blue: #01579b;
$magenta: #aa0c50;
$purple: #6200ee;
$yellow: #fcbf49;

$g6: #757575;
$g5: #9e9e9e;
$g4: #bdbdbd;
$g3: #e0e0e0;
$g2: #f5f5f5;
$g1: #fafafa;
$grey: #e5e5e5;

$white-transclusent: #ffffff99;
