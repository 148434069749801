@import 'sass/variables/fonts';
@import 'sass/variables/spaces';
@import 'sass/variables/transitions';
@import 'sass/variables/colors.scss';

@import 'sass/helpers/breakpoints';

.formButtonGroup {
	button {
		margin-left: 15px;
	}
	a > button {
		margin: 0;
	}
}

.skeletonLoader {
	background-color: $white;
	background-image: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
	animation: skletalAnimation 1s ease-in-out infinite;
}

@keyframes skletalAnimation {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

body,
html {
  background: #e5e5e5;
  color: #012e40;
}

.loadBg {
  content: '';
  display: block;
  position: fixed;
  z-index: 9990;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffff80;

  .centerElm {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    z-index: 9999;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.cntainer {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

button.ant-btn {
  border: 0;
  border-radius: 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  &:hover {
    font-weight: 600;
  }
  &:disabled:hover {
    background: $g6;
    opacity: 0.5;
    color: $white;
  }
  &.ant-btn-primary {
    background: $black;
  }
  &.light-button {
    background: $g1;
    color: $black;
  }
}

.error-line {
  font-size: 12px;
  margin: 10px 0 0 0;
  color: $red;
}

button.ant-btn.ant-btn-primary {
  color: $white;
}

.formErr {
  font-weight: bold;
  color: $red;
}

.nowrap {
  white-space: nowrap;
}

.anticon-check-circle {
  margin-right: 0.25em;

  svg {
    path {
      fill: $primary;

      &:nth-child(3) {
        fill: $white;
      }
    }
  }
}

.ant-avatar.ant-avatar > img {
  object-fit: contain;
}

.ant-modal {
  color: $black !important;
}

// ANT.DESIGN TABS GLOBAL

.ant-tabs.ant-tabs-top {
  overflow: visible;
  .ant-tabs-nav {
    margin-bottom: 40px;
    &::before {
      border-bottom: 0 transparent;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #78909c;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $primary;
          }
        }
        .tab-count {
          display: inline-block;
          background: #ed7b30;
          border-radius: 12px;
          font-size: 12px;
          line-height: 15px;
          color: $white;
          padding: 5px 10px;
          font-weight: bold;
        }
      }
      .ant-tabs-ink-bar {
        background: $primary;
      }
    }
  }
}

// ANT.DESIGN TABLE GLOBAL

.ant-table-wrapper {
  .ant-table {
    table {
      border-collapse: separate;
      border-spacing: 0 15px !important;
      .ant-table-thead {
        * {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
        .ant-table-cell {
          background: $g2;
          padding: 15px 25px;
          border-bottom: 0 transparent;
          color: #90a4ae;
          &:first-child {
            border-radius: 8px 0 0 8px;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
          }
          .ant-table-column-sorters {
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          .ant-table-selection {
            label.ant-checkbox-wrapper {
              padding: 0;
              margin: 0;
              background: transparent;
            }
          }
        }
      }
      .ant-table-tbody {
        tr.ant-table-row:hover > td {
          background: #fafafa;
        }
        * {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          align-self: center;
        }
        .ant-table-cell {
          background: $white;
          padding: 25px;
          border-top: 1px solid #cfd8dc;
          border-bottom: 1px solid #cfd8dc;
          &:first-child {
            background: transparent;
            padding: 15px 5px;
            border: 0 transparent;
          }
          &:nth-child(2) {
            border-radius: 8px 0 0 8px;
            border-left: 1px solid #cfd8dc;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
            border-right: 1px solid #cfd8dc;
          }
          &.ant-table-selection-column {
            label.ant-checkbox-wrapper {
              background: transparent;
            }
          }
        }
      }
    }
  }

  &.full {
    .ant-table table {
      .ant-table-thead .ant-table-cell {
        &:first-child {
          background: $g2;
          padding: 15px 25px;
          border-radius: 8px 0 0 8px;
        }

        &:nth-child(2) {
          border-radius: 0;
        }
      }

      .ant-table-tbody .ant-table-cell {
        &:first-child {
          background: $white;
          padding: 25px;
          border-top: 1px solid #cfd8dc;
          border-bottom: 1px solid #cfd8dc;
          border-left: 1px solid #cfd8dc;
          border-radius: 8px 0 0 8px;
        }

        &:nth-child(2) {
          border-radius: 0;
          border-left: 0;
        }
      }
    }
  }
}

.no-selection {
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background: $g2;
        padding: 15px 25px;
        border: 0 transparent;
        color: #90a4ae;
        &:first-child {
          background: $g2;
          padding: 15px 25px;
          border-radius: 8px 0 0 8px;
        }
        &:nth-child(2) {
          border-radius: 0;
          border-left: 0 transparent;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
          border-right: 0 transparent;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        &:first-child {
          border-radius: 8px 0 0 8px;
          padding: 15px 25px;
          border: 1px solid #cfd8dc;
          border-right: 0 transparent;
        }
        &:nth-child(2) {
          border-radius: 0;
          border-left: 0 transparent;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
          border-right: 1px solid #cfd8dc;
        }
        &.ant-table-selection-column {
          label.ant-checkbox-wrapper {
            background: transparent;
          }
        }
      }
    }
  }
}

.ant-table-expanded-row {
  & > .ant-table-cell {
    border-right: 0 transparent !important;
  }
  .ant-table-wrapper::before,
  .ant-table-wrapper::after {
    display: none;
  }
}

.anticon.anticon-caret-up.ant-table-column-sorter-up,
.anticon.anticon-caret-down.ant-table-column-sorter-down {
  font-size: 16px;
}
.anticon.anticon-caret-up.ant-table-column-sorter-up.active,
.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  color: $primary;
}

// ANTD DATEPICKER GLOBAL
.ant-picker-input > input ~ .ant-picker-clear {
  opacity: 1;
}

//ANT>DESIGN DROPDOWN GLOBAL

.ant-dropdown {
  .ant-dropdown-menu {
    padding: 8px 0;
    .ant-dropdown-menu-item {
      padding: 8px 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $primary;
      &.ant-dropdown-menu-item-disabled {
        color: $g6;
      }
    }
  }
}

.popover-title-spacing {
  padding: 10px 0;
  min-width: 500px;
}

.table-action-button {
  padding: 10px;
  color: $primary;
  border-radius: 6px;
  box-shadow: 0px 0px 16px rgba(34, 34, 34, 0.12);
}

.green-check.green-check {
  color: $mid-green !important;
}

.table-selection-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
}

// ----------------------------------------------------------------
// GLOBAL TABBED BUTTON GROUPS
.buttoned-tabs-holder {
  text-align: center;
  margin: 25px 0;
  .buttoned-tabs {
    display: inline-flex;
    flex-flow: row;
    filter: drop-shadow(0px 4px 16px rgba(189, 189, 189, 0.32));
    .ant-radio-button-wrapper {
      border-color: $tab-grey;
      span {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
      }
      &:hover {
        color: $tab-grey;
        background: $white;
      }
      &::before {
        background: $tab-grey;
      }
      &:first-child {
        border: 1px solid $tab-grey;
        border-color: $tab-grey;
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border: 1px solid $tab-grey;
        border-left: 1px;
        border-radius: 0 4px 4px 0;
      }
    }
    .ant-radio-button-wrapper-checked {
      background: $tab-grey;
      span {
        color: $white;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
      }
      &:hover {
        color: $white;
        background: $tab-grey;
        border-color: $tab-grey;
        &::before {
          background: $tab-grey;
        }
      }
      &:focus-within {
        box-shadow: 0 0 0 transparent;
      }
      &:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
        border-right-color: $tab-grey;
        border-radius: 4px 0px 0px 4px;
      }
      &:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:last-child {
        &::before {
          background: $tab-grey;
        }
        border-left-color: $tab-grey;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}

// ----------------------------------------------------------------

.tableTop {
  display: flex;

  span.ant-input-group-wrapper {
    max-width: 50vw;

    .ant-input-group-addon,
    input {
      height: 50px;
      min-width: 50px;
    }
  }

  > button {
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }
}

.notification {
  font-size: 14px;
  margin-bottom: 20px;
  color: $primary;
}

.ant-table-cell,
.ant-table-cell * {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}

.ant-btn.secondary-button {
  border: 1px solid;
}

.strength-indicator {
  & + div {
    max-width: 90%;
    margin-top: 10px;
  }
}

.logoArea img {
  max-width: 200px;
}
.logoAreaOnboarding img {
  max-width: 200px;
}

.common-error-title {
  font-size: 18px;
  font-weight: 700;
  color: #f44336;
  margin-bottom: 8px;
}

.common-error-message {
    font-size: 12px;
    font-weight: 600;
}