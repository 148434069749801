@import 'default';
@import 'normalise';
@import 'form-reset';
@import './icons.scss';

.ipContainer {
  margin-top: 30px;
  text-align: left;
  label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .ant-input,
  .ant-input-password,
  .ant-picker {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
  }
  .ant-input-password {
    padding: 0 15px 0 0;
    &.padded {
      padding: 15px;
    }
    .ant-input {
      margin-top: 0;
      border: 0;
      padding: 15px;
    }
  }
  .termsConditions {
    background: transparent;
    padding-left: 0;
    color: #78909c;
    &:hover {
      background: transparent;
    }
    .hyperlink {
      font-weight: 600;
      color: $primary;
    }
  }
}

.mb-5 {
  margin-bottom: 5rem;
}
