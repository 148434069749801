// Utilities
// Single purpose, immutable styles, that do one thing well.
// Only place styles class here, Not reusable variables

:root {
	@import 'margins';
	@import 'paddings';
	@import 'scrollbars';
	@import 'typography';
	@import 'monokai';

	.d-none {
		display: none;
	}
}
