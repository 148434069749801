@import 'variables/fonts';
@import 'variables/spaces';
@import 'variables/transitions';
@import 'variables/colors.scss';

@import 'helpers/breakpoints';

.formButtonGroup {
	button {
		margin-left: 15px;
	}
	a > button {
		margin: 0;
	}
}

.skeletonLoader {
	background-color: $white;
	background-image: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
	animation: skletalAnimation 1s ease-in-out infinite;
}

@keyframes skletalAnimation {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

@import 'variables/index.scss';

@import 'utilities/index';

@import 'trumps/index';

@import 'base/index';
